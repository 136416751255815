import styled from 'styled-components';
import bgImage from './stars.jpg';

export const AppWrapper = styled.div`
  background-image: url(${bgImage});
  background-size: 100%;
  background-repeat: no-repeat;
  background-color: #222222;
  text-align: left;
  margin: 0 auto;
  color: white;
  align-items: stretch;
`;

export const PanelWrapper = styled.section`
  display: flex;
  flex-direction: row;
  @media (max-width: 1600px) {
    flex-direction: column;
  }
`;
export const Panel = styled.section`
    padding: 4rem 6rem;
    @media(max-width: 1600px) {
      padding: 2rem 3rem;
      padding-bottom: 0;
    }
    flex: 1 0 0;
`;
export const LeftPanel = styled(Panel)`
`;
export const RightPanel = styled(Panel)`
  height: 100%;
  margin-top: 24px;
  @media (max-width: 1600px) {
    padding-top: 0;
    margin-top: 0;
  }
`;
export const SectionHeader = styled.header`
  h1 {
    margin-top:0;
    font-weight: normal;
    margin-bottom:0;
  }
`;

export const ProfileHeader = styled(SectionHeader)`
  aside {
    font-weight: normal;
    font-variant-caps: all-small-caps;
    font-size: 3rem;
    font-family: inherit;
  }
  h1 {
    font-family: 'Galada', cursive;
    line-height: 6.5rem;
    font-size: 6.5rem;
    @media(max-width: 1600px) {
      line-height: 5rem;
      font-size: 5rem;
    }
  }
  margin-top: 16px;
`;

export const ProfilePhoto = styled.img`
    border-radius: 50%;
    box-shadow: 0 0 2px rgba(255,255,255,0.4);
    margin-right: 2rem;
    align-self: center;
`;

export const ProjectsHeader = styled(SectionHeader)`
  h1 {
    font-weight: bold;
    margin-bottom: 1.6rem;
    font-size: 4rem;
    @media (max-width: 1600px) {
      font-size: 3rem;
      margin-top: 3.5rem;
    }
  }
`;

export const Projects = styled.div`
  >:last-child {
    margin-bottom: 0;
  }
`;

export const SNSLink = styled.a`
  font-size: 5rem;
  color: white;
  text-decoration: none;
  padding: 2.5rem;
  &:first-child {
    padding-left: 0;
  }
  &:hover {
    opacity: 0.5;
  }
  transition-duration: 0.5s;
  @media (max-width: 1600px) {
    font-size: 4rem;
    padding: 2rem;
  }
`;

export const SNSLinksWrapper = styled.section`
  margin-top: 3rem;
`;
export const IntroductionWrapper = styled.section`
  margin-top: 2.5rem;
  font-size: 4rem;
  p:first-child {
    margin-top: 0;
  }
  @media (max-width: 1600px) {
    font-size: 3rem;
  }
`;