const projects = {
  en: [
    {
      id: 0,
      period: "May 2020 - ",
      company: "AutoScale Inc.",
      title: "SocialDog",
      description: [
        "Twitter account management web and Android/iOS application",
        "Coding (back and front end), proposal/design of features/improvements",
        "Localization for English-speaking users",
        "400,000 users (as of September, 2020)",
      ],
      tech: [
        "devicon-react-plain",
        "devicon-typescript-plain",
        "devicon-php-plain",
        "styled-components",
      ],
      link: {
        title: "SocialDog",
        type: "external",
        url: "https://social-dog.net/",
      },
    },
    {
      id: 1,
      period: "Sept 2017 - Feb 2020",
      company: "NEC Solution Innovators",
      title: "CLUSTERPRO WebUI",
      description: [
        "Large scale (hundreds of screens) SPA dashboard web app for configuring and managing servers in a cluster",
        "Team of 2-3 people",
        "UI design and coding of front end",
        "Made use of components and dynamic generation of forms from YAML files to increase development efficiency, maintainability, and extensibility",
        "Released in 2018, after which focus was shifted to new features/improvements",
      ],
      tech: [
        "devicon-angularjs-plain",
        "devicon-typescript-plain",
        "devicon-sass-original",
      ],
      link: {
        title: "CLUSTERPRO X - 特長/機能",
        type: "external",
        url:
          "https://jpn.nec.com/clusterpro/clpx/function_update.html#anc-x40_3",
      },
    },
    {
      id: 2,
      period: "Jan 2020 -",
      company: "Personal Project",
      title: "reacket",
      description: [
        "React component to display a single-elimination tournament bracket",
      ],
      tech: ["devicon-react-original", "devicon-sass-original"],
      link: {
        title: "reacket",
        type: "github",
        url: "https://github.com/jez321/reacket",
      },
    },
    {
      id: 3,
      period: "Jun 2020 -",
      company: "Personal Project",
      title: "Chart the world",
      description: [
        "Website displaying music charts from around the world",
        "Uses react-query for server state and automatically updates charts via APIs and scraping.",
      ],
      tech: [
        "devicon-react-original",
        "devicon-postgresql-plain",
        "devicon-python-plain",
        "devicon-go-plain",
        "devicon-nginx-original",
        "styled-components",
      ],
      link: {
        title: "Chart the world",
        type: "external",
        url: "https://charttheworld.com/",
      },
    },
    {
      id: 4,
      period: "Jan 2010 - Feb 2020",
      company: "NEC Solution Innovators",
      title: "ITPF Portal",
      description: [
        "Portal for NEC server products including CMS and support case handling features",
        "Team of 2 people",
        "UI design and coding of front and back end",
        "Used both within Japan and by NEC subsidiaries globally",
      ],
      tech: [
        "devicon-jquery-plain",
        "devicon-csharp-plain",
        "mvc",
        "sqlserver",
      ],
    },
    {
      id: 5,
      period: "Sept 2016 - Jun 2018",
      company: "NEC Solution Innovators",
      title: "Greenhouse Management System",
      description: [
        "Web/tablet application to collect, upload, and display data from sensors placed in greenhouses, and configure rules to automate adjustment of the climate",
        "Team of 2-3 people",
        "UI design and coding of front and back end",
        "Designed with a focus on ease of use to enable operation by inexperienced greenhouse staff",
      ],
      tech: [
        "devicon-jquery-plain",
        "devicon-csharp-plain",
        "mvc",
        "sqlserver",
      ],
      link: {
        title: "HYPERPOST/圃場管理システム",
        type: "external",
        url: "https://www.nec-solutioninnovators.co.jp/sl/ghms/index.html",
      },
    },
  ],
  ja: [
    {
      id: 0,
      period: "May 2020 - ",
      company: "株式会社 AutoScale",
      title: "SocialDog",
      description: [
        "Twitterアカウント管理ウェブ/Android/iOSアプリケーション",
        "開発（フロント・バック）、新規機能・改善の提案/デザイン/実装",
        "英語圏ユーザーへのローカライズ",
        "40万ユーザー（2020年９月現在）",
      ],
      tech: [
        "devicon-react-plain",
        "devicon-typescript-plain",
        "devicon-php-plain",
        "styled-components",
      ],
      link: {
        title: "SocialDog",
        type: "external",
        url: "https://social-dog.net/",
      },
    },
    {
      id: 1,
      period: "2017年9月 ～ 2020年2月",
      company: "NECソリューションイノベータ",
      title: "CLUSTERPRO WebUI",
      description: [
        "クラスタとサーバを管理するための大規模（数百画面）SPAダッシュボードウェブアプリ",
        "2-3人チーム",
        "UIデザイン、フロントエンド開発を担当",
        "コンポーネントとYAMLからフォームの自動生成で効率・拡張性を向上",
        "2018年にリリース、その後改善・新機能を開発",
      ],
      tech: [
        "devicon-angularjs-plain",
        "devicon-typescript-plain",
        "devicon-sass-original",
      ],
      link: {
        title: "CLUSTERPRO X - 特長/機能",
        type: "external",
        url:
          "https://jpn.nec.com/clusterpro/clpx/function_update.html#anc-x40_3",
      },
    },
    {
      id: 2,
      period: "2020年2月 ～",
      company: "個人プロジェクト",
      title: "reacket",
      description: [
        "シングル・エリミネーション・トーナメントブラケットを表示するReactのコンポーネント",
      ],
      tech: ["devicon-react-original", "devicon-sass-original"],
      link: {
        title: "reacket",
        type: "github",
        url: "https://github.com/jez321/reacket",
      },
    },
    {
      id: 3,
      period: "2020年5月 ～",
      company: "個人プロジェクト",
      title: "Chart the world",
      description: [
        "世界の音楽ランキングを表示するウェブサイト",
        "react-queryでサーバーステートを管理、APIの利用とスクレイピングで自動更新。",
      ],
      tech: [
        "devicon-react-original",
        "devicon-postgresql-plain",
        "devicon-python-plain",
        "devicon-go-plain",
        "devicon-nginx-original",
        "styled-components",
      ],
      link: {
        title: "Chart the world",
        type: "external",
        url: "https://charttheworld.com/",
      },
    },
    {
      id: 4,
      period: "2010年1月 ～ 2020年2月",
      company: "NECソリューションイノベータ",
      title: "ITPF Portal",
      description: [
        "NECサーバ製品向けのCMS・サポート案件管理機能があるポータルサイト",
        "2人チーム",
        "UIデザイン、バックエンド・フロントエンド開発を担当",
        "国内または海外現地法人ユーザ向け",
      ],
      tech: [
        "devicon-jquery-plain",
        "devicon-csharp-plain",
        "mvc",
        "sqlserver",
      ],
    },
    {
      id: 5,
      period: "2016年9月 ～ 2018年6月",
      company: "NECソリューションイノベータ",
      title: "HYPERPOST/圃場管理システム",
      description: [
        "圃場内センサーから収集されたデータをクラウドサーバにアップロード、ウェブまたはタブレットアプリで表示する",
        "2-3人チーム",
        "UIデザイン、フロントエンド開発を担当",
        "経験の少ない圃場スタッフが使えるようにUI/UXを重視",
      ],
      tech: [
        "devicon-jquery-plain",
        "devicon-csharp-plain",
        "mvc",
        "sqlserver",
      ],
      link: {
        title: "HYPERPOST/圃場管理システム",
        type: "external",
        url: "https://www.nec-solutioninnovators.co.jp/sl/ghms/index.html",
      },
    },
  ],
};

export default projects;
