import styled from 'styled-components';

export const ProjectMetaWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
  font-size:2rem;
  opacity: 0.8;
  @media(max-width: 800px) {
    flex-direction: column;
    display: none;
  }
`;
export const ProjectPeriod = styled.div`
  margin-bottom: 0.2rem;
`;
export const ProjectCompany = styled.div`
  margin-bottom: 0.2rem;
`;
export const ProjectTitle = styled.div`
  font-size:3rem;
  font-weight: normal;
  @media (max-width: 1600px) {
    font-size: 2.5rem;
  }
`;
export const ProjectDescription = styled.div`
  font-size: 2rem;
`;
export const ProjectWrapper = styled.div`
  margin-bottom: 2rem;
  padding: 2rem;
  background: rgba(255,255,255,0.05);
  border-radius: 10px;
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
      margin: 1rem 0;
    }
  }
`;
export const ProjectLink = styled.a`
  font-size: 3rem;
  color: white;
  text-decoration: none;
  margin-left: 1rem;
  &:hover {
    opacity: 0.5;
  }
  transition-duration: 0.5s;
  @media (max-width: 1600px) {
    font-size: 2.5rem;
  }
`;

export const ProjectTech = styled.section`
  i {
    padding: 0.5rem 1rem;
    font-size: 3.2rem;
    display: inline-block;
  }
  p {
    font-size: 1.6rem;
    padding: 0.5rem 1rem;
    display: inline-block;
    margin: 0;
    vertical-align: 0.8rem;
    line-height: 3.2rem;
  }
  margin-top: 1.6rem;
`;