import React, { useState } from 'react';
import {
  AppWrapper,
  LeftPanel,
  RightPanel,
  ProfileHeader,
  ProjectsHeader,
  Projects,
  SNSLink,
  PanelWrapper,
  ProfilePhoto,
  SNSLinksWrapper,
  IntroductionWrapper,
} from './App.styles.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faBlog } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import Fade from 'react-reveal/Fade';
import Project from './components/Project/Project.component';
import projects from './projects.js';
import text from './text.js';

function App() {
  const [lang, setLang] = useState('en');
  const projectsJsx = projects[lang].map((data) => (
    <Fade key={data.id} bottom>
      <Project data={data} />
    </Fade>
  ));
  return (
    <AppWrapper>
      <div style={{ textAlign: 'right' }}>
        <button onClick={() => setLang(lang === 'en' ? 'ja' : 'en')}>
          {lang === 'en' ? '日本語' : 'English'}
        </button>
      </div>
      <PanelWrapper>
        <LeftPanel>
          <Fade top>
            <div style={{ display: 'flex' }}>
              <ProfilePhoto width="110px" alt="Profile photo" src="./jj.jpg" />
              <ProfileHeader>
                <h1>Jeremy Johns</h1>
                <aside>Web Developer</aside>
              </ProfileHeader>
            </div>
            <IntroductionWrapper>
              {text[lang].greeting.map((gp, index) => (
                <p key={index}>{gp}</p>
              ))}
            </IntroductionWrapper>
            <SNSLinksWrapper>
              <SNSLink
                title="GitHub"
                target="_blank"
                rel="noopener noreferrer"
                href="https://github.com/jez321">
                <FontAwesomeIcon icon={faGithub} />
              </SNSLink>
              <SNSLink
                title="LinkedIn"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/in/jez321/">
                <FontAwesomeIcon icon={faLinkedin} />
              </SNSLink>
              <SNSLink
                title="Blog"
                target="_blank"
                rel="noopener noreferrer"
                href="https://blog.jjohns.com">
                <FontAwesomeIcon icon={faBlog} />
              </SNSLink>
              <SNSLink title="Email" href="mailto:jeremy@jjohns.com">
                <FontAwesomeIcon icon={faEnvelope} />
              </SNSLink>
            </SNSLinksWrapper>
          </Fade>
        </LeftPanel>
        <RightPanel>
          <ProjectsHeader>
            <h1>{text[lang].projects}</h1>
          </ProjectsHeader>
          <Projects>{projectsJsx}</Projects>
        </RightPanel>
      </PanelWrapper>
    </AppWrapper>
  );
}

export default App;
