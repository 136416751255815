const text =
{
  "en": {
    "projects": "Selected Projects",
    "greeting": ["Greetings! I'm a web developer living in Japan with 10 years experience in software development. I specialize in frontend but also have experience with backend development.", "In addition to coding, I have experience in UI and UX design and strive to make create simple, intuitive, pleasing experiences for users.", "Thanks for visiting!"],
  },
  "ja": {
    "projects": "一部のプロジェクト",
    "greeting": ["初めまして！　日本語に住んでおり、１０年程ウェブ開発をやらせてもらっています。主にフロントエンドをやっていますが、バックエンド開発の経験もあります.", "プログラミングに加え、UI/UXデザインの経験もあり、ユーザにシンプル・使いやすい・楽しいものが提供できるように努力しています。", "サイトを見てくれてありがとうございます！"],
  }
};
export default text;