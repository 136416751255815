import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons';
import { ProjectPeriod, ProjectTitle, ProjectDescription, ProjectWrapper, ProjectMetaWrapper, ProjectCompany, ProjectLink, ProjectTech } from './Project.styles.js'

const Project = ({ data }) => {
  return (
    <ProjectWrapper>
      <ProjectMetaWrapper>
        <ProjectPeriod>{data.period}</ProjectPeriod>
        <ProjectCompany>{data.company}</ProjectCompany>
      </ProjectMetaWrapper>
      <ProjectTitle>{data.title}&nbsp;{data.link ? (
        <ProjectLink target="_blank" rel="noopener noreferrer" href={data.link.url} title={data.link.title}>
          <FontAwesomeIcon icon={data.link.type === "github" ? faGithub : faExternalLinkSquareAlt} /> {data.link.icon}
        </ProjectLink>) : null
      }
      </ProjectTitle>
      <ProjectDescription>
        <ul>
          {data.description.map((dd, index) => (
            <li key={index}>{dd}</li>
          ))}
        </ul>
      </ProjectDescription>
      <ProjectTech>
        {data.tech.map((t, index) => {
          switch (t) {
            case "mvc":
              return <p key={index}>.Net MVC</p>
            case "sqlserver":
              return <p key={index}>SQL Server</p>
            case "styled-components":
              return <p key={index}>styled-components</p>
            default:
              return <i key={index} className={t}></i>
          }
        })}
      </ProjectTech>
    </ProjectWrapper>
  )
}

export default Project;